import React from 'react'

const NetworkDispensaryList = ({ listDispensary }) => {
 return (
  <ul>
   {listDispensary?.data?.map((item, index) => (
    <li key={index}>
     <a rel="noreferrer" href={item.link} target="_blank">
      {item.title}
     </a>
    </li>
   ))}
  </ul>
 )
}
export default NetworkDispensaryList
